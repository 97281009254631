.wrapper {
  min-height: calc(100vh - 70px);
  /*margin-bottom: -40px;*/
}

.footer {
    height: 50px;
}

.fixed_header{
    
    table-layout: fixed;
    border-collapse: collapse;
}

.no-flex-wrap {
    flex-wrap: nowrap;
}

.token-dropdown {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-top: 7px;
  padding-bottom: 7px;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 100px;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header thead {
  background: black;
  color:#fff;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
  
}

.margin-left-20-font-size {
    margin-left: -20px;
    font-size: 1.4rem;
}

.aligned-form {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -45%);
}

.ccol-4 {
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  max-width: 38%;
}

.ccol-8 {
  -ms-flex: 0 0 59%;
  flex: 0 0 59%;
  max-width: 59%;
}

.container {
    color: #888888;
    /*max-width: 1080px;*/
}

.dl-horizontal dt {
    white-space: normal;
    width: 250px;
    clear: left;
    text-align: right;
}

.color-transition {
    -webkit-transition: color 2s;
    transition: color 2s;

}

.btn-default {
    background-color: #dddddd;
}

.max-width {
    max-width: 100%;
}

.dropdown-item-disabled {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  background-color: transparent; 
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  cursor: none;
}

.dropdown-item-body-disabled {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #999;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
}

.text-overflow-label{
    position:relative;
    display:block;
    transition:background 0.3s;
    cursor:pointer;
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    padding-right: 10px;
}

.text-overflow-label:after{
    content:"";
    position:absolute;
    display:block;
    top:50%;
    right:0px;
    width:0;
    height:0;
    border-top:4px solid rgba(0,0,0,.5);
    border-bottom:0 solid rgba(0,0,0,.5);
    border-left:4px solid transparent;
    border-right:4px solid transparent;
    transition:border-bottom .1s, border-top .1s .1s;
}

@media (max-width: 991px) {
    .no-left-padding {
        padding-left: 15px;
    }
    .dl-horizontal dt {
        width: 100%;
        clear: right;
        text-align: left;
    }
    .dl-horizontal dt {
        width: 100%;
        clear: right;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .margin-top-sm {
        margin-top: 7px;
    }
    .margin-bottom-sm {
        margin-bottom: 0px;
    }

    .margin-bottom-8 {
        margin-bottom: 15px;
    }

    .margin-bottom-5 {
        padding-bottom: 5px;
    }
    .max-width {
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .text-center {
      text-align: left !important;
    }
    .no-flex-wrap {
        flex-wrap: wrap;
    }

    .no-right-padding {
        padding-right: 0px;
    }
}

@media (max-width: 412px) {
    .margin-left-20-font-size {
        margin-left: 0px;
        font-size: 1.0rem;
    }
}

@media (max-width: 575px) {
    .add-files .col-sm-10, .add-files .col-xs-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .add-files .col-sm-9, .add-files .col-xs-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .add-files .col-sm-1, .add-files .col-xs-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
}

#dscLeftParamArea label + span,
#dsvLeftParamArea label + span {
    margin-left: -17px;
    margin-top: 5px;
}

.grey-tooltip + .tooltip > .tooltip-inner {
    background-color: #555555;
}
.grey-tooltip + .tooltip > .tooltip-arrow {
    border-bottom-color: #555555;
}

textarea {
    resize: none;
    overflow-x: hidden;
}